import React from 'react';

const Terms = () => {
	return (
		<object
			data='https://res.cloudinary.com/domruybbg/image/upload/v1692659613/storage/domus_docs/TERMINOS_Y_CONDICIONES_-_DOMUS_onbeiq.pdf'
			type='application/pdf'
			width='100%'
			height='100%'>
			<div> </div>
		</object>
	);
};

export default Terms;
