import React from 'react';
import { demoPages, noMenuPages, multiCondosPages } from '../menu';
import EmptytHeader from '../pages/common/Headers/EmptyHeader';
import OnlyImagetHeader from '../pages/common/Headers/OnlyImageHeader';

const headers = [
	{ path: noMenuPages.login.path, element: null, exact: true },
	{ path: noMenuPages.signUp.path, element: null, exact: true },
	{ path: noMenuPages.administrator.path, element: null, exact: true },
	{ path: noMenuPages.loaderLaunch.path, element: null, exact: true },
	{ path: noMenuPages.recoverPasswordStepOne.path, element: null, exact: true },
	{ path: noMenuPages.recoverPasswordStepTwo.path, element: null, exact: true },
	{ path: noMenuPages.pricingTable.path, element: null, exact: true },
	{ path: noMenuPages.page404.path, element: null, exact: true },
	{ path: noMenuPages.pricingTable.path, element: <EmptytHeader />, exact: true },
	{ path: noMenuPages.invite.path, element: null, exact: true },
	{ path: noMenuPages.termsAndConditionsApp.path, element: null, exact: true },
	{ path: demoPages.dashboard.path, element: <EmptytHeader />, exact: true },
	{
		path: demoPages.crmDashboard.subMenu.vehicle.path,
		element: <EmptytHeader />,
		exact: true,
	},
	{ path: demoPages.summary.subMenu.supplier.path, element: <EmptytHeader />, exact: true },
	{ path: demoPages.summary.subMenu.comite.path, element: <EmptytHeader />, exact: true },
	{
		path: demoPages.crmDashboard.subMenu.morosos.path,
		element: <EmptytHeader />,
		exact: true,
	},
	{
		path: demoPages.crmDashboard.subMenu.resident.path,
		element: <EmptytHeader />,
		exact: true,
	},
	{
		path: demoPages.crmDashboard.subMenu.residentBalance.path,
		element: <EmptytHeader />,
		exact: true,
	},
	{
		path: demoPages.crmDashboard.subMenu.residentRequest.path,
		element: <EmptytHeader />,
		exact: true,
	},
	{
		path: demoPages.crmDashboard.subMenu.gridBoxed.path,
		element: <EmptytHeader />,
		exact: true,
	},
	{ path: demoPages.summary.subMenu.areas.path, element: <EmptytHeader />, exact: true },
	{ path: demoPages.listBoxed.path, element: <EmptytHeader />, exact: true },
	{ path: demoPages.editFluid.subMenu.egress.path, element: <EmptytHeader />, exact: true },
	{ path: demoPages.listBoxed.subMenu.activities.path, element: <EmptytHeader />, exact: true },
	{ path: demoPages.listBoxed.subMenu.calendar.path, element: <EmptytHeader />, exact: true },
	{ path: demoPages.editModern.subMenu.goals.path, element: <EmptytHeader />, exact: true },
	{ path: demoPages.editModern.subMenu.communique.path, element: <EmptytHeader />, exact: true },
	{ path: demoPages.fluidSingle.path, element: <EmptytHeader />, exact: true },
	{ path: demoPages.fluidSingle.subMenu.guests.path, element: <EmptytHeader />, exact: true },
	{ path: demoPages.fluidSingle.subMenu.blackList.path, element: <EmptytHeader />, exact: true },
	{ path: demoPages.summary.subMenu.editBoxed.path, element: <EmptytHeader />, exact: true },
	{ path: demoPages.editFluid.subMenu.ingress.path, element: <EmptytHeader />, exact: true },
	{ path: demoPages.editFluid.subMenu.balance.path, element: <EmptytHeader />, exact: true },
	{ path: demoPages.editFluid.subMenu.dues.path, element: <EmptytHeader />, exact: true },
	{ path: demoPages.editFluid.subMenu.charges.path, element: <EmptytHeader />, exact: true },
	{ path: demoPages.editFluid.subMenu.accounts.path, element: <EmptytHeader />, exact: true },
	{ path: demoPages.editFluid.subMenu.budget.path, element: <EmptytHeader />, exact: true },
	{ path: demoPages.summary.subMenu.documents.path, element: <EmptytHeader />, exact: true },
	{ path: demoPages.editInModal.path, element: <EmptytHeader />, exact: true },
	{ path: demoPages.listFluid.path, element: <EmptytHeader />, exact: true },
	{ path: demoPages.crmDashboard.subMenu.workers.path, element: <EmptytHeader />, exact: true },
	{ path: demoPages.summary.subMenu.parking.path, element: <EmptytHeader />, exact: true },
	{ path: demoPages.editInModal.subMenu.invite.path, element: <EmptytHeader />, exact: true },
	{ path: multiCondosPages.workSpace.path, element: <OnlyImagetHeader />, exact: true },
	{ path: multiCondosPages.users.path, element: <OnlyImagetHeader />, exact: true },
	{ path: `*`, element: <EmptytHeader /> },
];

export default headers;
