export const demoPages = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: 'dashboard',
		icon: 'Dashboard',
		subMenu: null,
	},
	crmDashboard: {
		id: 'crmDashboard',
		text: 'Residentes',
		path: 'demo-pages/ResidentsPage',
		icon: 'EmojiPeople',
		subMenu: {
			resident: {
				id: 'resident',
				text: 'Residentes',
				path: 'resident',
				icon: 'DriveFileRenameOutline',
			},
			vehicle: {
				id: 'Vehicle',
				text: 'Vehículos',
				path: 'vehicle',
				icon: 'DirectionsCar',
			},
			gridBoxed: {
				id: 'gridBoxed',
				text: 'Mascotas',
				path: 'pets',
				icon: 'Pets',
			},
			residentBalance: {
				id: 'residentBalance',
				text: 'Balance',
				path: 'residentBalance',
				icon: 'AccountBalance',
			},
			agreemens: {
				id: 'Agreements',
				text: 'Saldo inicial',
				path: 'agreements',
				icon: 'Inventory',
			},
			morosos: {
				id: 'Morosos',
				text: 'Morosos',
				path: 'morosos',
				icon: 'Block',
			},
			residentRequest: {
				id: 'residentRequest',
				text: 'Solicitudes',
				path: 'residentRequest',
				icon: 'LocalPostOffice',
			},
			invitations: {
				id: 'invitations',
				text: 'Invitaciones',
				path: 'invitation',
				icon: 'ConfirmationNumber',
			},
			reports: {
				id: 'report',
				text: 'Reportes',
				path: 'report',
				icon: 'Assignment',
			},
			workers: {
				id: 'workers',
				text: 'Trabajadores',
				path: 'workers',
				icon: 'PersonBadge',
			},
		},
	},
	summary: {
		id: 'summary',
		text: 'Privada',
		path: 'summary',
		icon: 'HomeWork',
		subMenu: {
			condo: {
				id: 'condo',
				text: 'Registro',
				path: 'condo',
				icon: 'Home',
			},
			areas: {
				id: 'areas',
				text: 'Áreas comunes',
				path: 'areas',
				icon: 'Pool',
			},
			market: {
				id: 'market',
				text: 'Market',
				path: 'market',
				icon: 'Storefront',
			},
			supplier: {
				id: 'supplier',
				text: 'Proveedores',
				path: 'supplier',
				icon: 'Truck',
			},
			editBoxed: {
				id: 'editBoxed',
				text: 'Servicios externos',
				path: 'external-services',
				icon: 'Tools',
			},
			quiz: {
				id: 'quiz',
				text: 'Encuestas',
				path: 'quiz',
				icon: 'HowToVote',
			},
			comite: {
				id: 'Comite',
				text: 'Comité',
				path: 'comite',
				icon: 'People',
			},
			documents: {
				id: 'documents',
				text: 'Documentos',
				path: 'documents',
				icon: 'AttachFile',
			},
			parking: {
				id: 'parking',
				text: 'Estacinamiento',
				path: 'parking',
				icon: 'DirectionsCar',
			},
		},
	},
	fluidSingle: {
		id: 'fluidSingle',
		text: 'Visitantes',
		path: 'single-pages/fluid',
		icon: 'Group',
		subMenu: {
			guests: {
				id: 'guests',
				text: 'Invitados',
				path: 'guests',
				icon: 'People',
			},
			blackList: {
				id: 'blackList',
				text: 'Blacklist',
				path: 'blackList',
				icon: 'EmojiAngryFill',
			},
		},
	},
	listBoxed: {
		id: 'listBoxed',
		text: 'Actividades',
		path: 'eventsCalendar',
		icon: 'Assignment',
		subMenu: {
			calendar: {
				id: 'calendar',
				text: 'Reservaciones',
				path: 'eventsCalendar',
				icon: 'PermContactCalendar',
			},
			activities: {
				id: 'activities',
				text: 'Agenda',
				path: 'activities',
				icon: 'EventNote',
			},
		},
	},
	editModern: {
		id: 'editModern',
		text: 'Comunicación',
		path: 'edit-pages/modern',
		icon: 'Campaign',
		subMenu: {
			goals: {
				id: 'goals',
				text: 'Metas y logros',
				path: 'goals',
				icon: 'HowToReg',
			},
			communique: {
				id: 'communique',
				text: 'Comunicados',
				path: 'communique',
				icon: 'Send',
			},
		},
	},
	editFluid: {
		id: 'editFluid',
		text: 'Finanzas',
		path: 'edit-pages/fluid',
		icon: 'StackedBarChart',
		subMenu: {
			accounts: {
				id: 'accounts',
				text: 'Cuentas',
				path: 'accounts',
				icon: 'CreditCard',
			},
			dues: {
				id: 'dues',
				text: 'Registro de cuotas',
				path: 'dues',
				icon: 'AppRegistration',
			},
			charges: {
				id: 'charges',
				text: 'Aplicar cuota',
				path: 'charges',
				icon: 'Payments',
			},
			ingress: {
				id: 'Ingress',
				text: 'Otros ingresos',
				path: 'ingress',
				icon: 'MonetizationOn',
			},
			egress: {
				id: 'Egress',
				text: 'Gastos',
				path: 'egress',
				icon: 'MoneyOffCsred',
			},
			budget: {
				id: 'budget',
				text: 'Presupuestos',
				path: 'budget',
				icon: 'Assessment',
			},
			balance: {
				id: 'Balance',
				text: 'Balance',
				path: 'balance',
				icon: 'AccountBalance',
			},
			adjustment: {
				id: 'adjustment',
				text: 'Ajustar saldos',
				path: 'adjustment',
				icon: 'CreditScore',
			},
			reporter: {
				id: 'reporter',
				text: 'Estado de resultados',
				path: 'reporter',
				icon: 'AreaChart',
			},
		},
	},
	listFluid: {
		id: 'listFluid',
		text: 'Control de acceso',
		path: 'access',
		icon: 'LocalPolice',
		subMenu: {
			addStickers: {
				id: 'stickers',
				text: 'ID cards',
				path: 'stickers',
				icon: 'Style',
			},
		},
	},
	editInModal: {
		id: 'editInModal',
		text: 'Configuraciones',
		path: 'settings',
		icon: 'PieChartFill',
		subMenu: {
			administrators: {
				id: 'administrators',
				text: 'Perfil',
				path: 'administrators',
				icon: 'Groups',
			},
			invite: {
				id: 'invitationToManege',
				text: 'Invitaciones',
				path: 'invitationToManege',
				icon: 'EnvelopeFill',
			},
		},
	},
};

export const multiCondosPages = {
	workSpace: {
		id: 'workSpace',
		text: 'Espacio de trabajo',
		path: 'workSpace',
		icon: 'PersonWorkspace',
		subMenu: null,
	},
	users: {
		id: 'users',
		text: 'Usuarios',
		path: 'users',
		icon: 'Groups',
		subMenu: null,
	},
	/* dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: 'dashboard',
		icon: 'Dashboard',
		subMenu: null,
	},
	crmDashboard: {
		id: 'crmDashboard',
		text: 'Residentes',
		path: 'demo-pages/ResidentsPage',
		icon: 'EmojiPeople',
		subMenu: {
			resident: {
				id: 'resident',
				text: 'Residentes',
				path: 'resident',
				icon: 'DriveFileRenameOutline',
			},
			vehicle: {
				id: 'Vehicle',
				text: 'Vehículos',
				path: 'vehicle',
				icon: 'DirectionsCar',
			},
			gridBoxed: {
				id: 'gridBoxed',
				text: 'Mascotas',
				path: 'pets',
				icon: 'Pets',
			},
			residentBalance: {
				id: 'residentBalance',
				text: 'Balance',
				path: 'residentBalance',
				icon: 'AccountBalance',
			},
			agreemens: {
				id: 'Agreements',
				text: 'Saldo inicial',
				path: 'agreements',
				icon: 'Inventory',
			},
			morosos: {
				id: 'Morosos',
				text: 'Morosos',
				path: 'morosos',
				icon: 'Block',
			},
			residentRequest: {
				id: 'residentRequest',
				text: 'Solicitudes',
				path: 'residentRequest',
				icon: 'LocalPostOffice',
			},
			invitations: {
				id: 'invitations',
				text: 'Invitaciones',
				path: 'invitation',
				icon: 'ConfirmationNumber',
			},
			reports: {
				id: 'report',
				text: 'Reportes',
				path: 'report',
				icon: 'Assignment',
			},
			workers: {
				id: 'workers',
				text: 'Trabajadores',
				path: 'workers',
				icon: 'PersonBadge',
			},
		},
	},
	summary: {
		id: 'summary',
		text: 'Privada',
		path: 'summary',
		icon: 'HomeWork',
		subMenu: {
			condo: {
				id: 'condo',
				text: 'Registro',
				path: 'condo',
				icon: 'Home',
			},
			areas: {
				id: 'areas',
				text: 'Áreas comunes',
				path: 'areas',
				icon: 'Pool',
			},
			market: {
				id: 'market',
				text: 'Market',
				path: 'market',
				icon: 'Storefront',
			},
			supplier: {
				id: 'supplier',
				text: 'Proveedores',
				path: 'supplier',
				icon: 'Truck',
			},
			editBoxed: {
				id: 'editBoxed',
				text: 'Servicios externos',
				path: 'external-services',
				icon: 'Tools',
			},
			quiz: {
				id: 'quiz',
				text: 'Encuestas',
				path: 'quiz',
				icon: 'HowToVote',
			},
			comite: {
				id: 'Comite',
				text: 'Comité',
				path: 'comite',
				icon: 'People',
			},
			documents: {
				id: 'documents',
				text: 'Documentos',
				path: 'documents',
				icon: 'AttachFile',
			},
			parking: {
				id: 'parking',
				text: 'Estacinamiento',
				path: 'parking',
				icon: 'DirectionsCar',
			},
		},
	},
	fluidSingle: {
		id: 'fluidSingle',
		text: 'Visitantes',
		path: 'single-pages/fluid',
		icon: 'Group',
		subMenu: {
			guests: {
				id: 'guests',
				text: 'Invitados',
				path: 'guests',
				icon: 'People',
			},
			blackList: {
				id: 'blackList',
				text: 'Blacklist',
				path: 'blackList',
				icon: 'EmojiAngryFill',
			},
		},
	},
	listBoxed: {
		id: 'listBoxed',
		text: 'Actividades',
		path: 'eventsCalendar',
		icon: 'Assignment',
		subMenu: {
			calendar: {
				id: 'calendar',
				text: 'Reservaciones',
				path: 'eventsCalendar',
				icon: 'PermContactCalendar',
			},
			activities: {
				id: 'activities',
				text: 'Agenda',
				path: 'activities',
				icon: 'EventNote',
			},
		},
	},
	editModern: {
		id: 'editModern',
		text: 'Comunicación',
		path: 'edit-pages/modern',
		icon: 'Campaign',
		subMenu: {
			goals: {
				id: 'goals',
				text: 'Metas y logros',
				path: 'goals',
				icon: 'HowToReg',
			},
			communique: {
				id: 'communique',
				text: 'Comunicados',
				path: 'communique',
				icon: 'Send',
			},
		},
	},
	editFluid: {
		id: 'editFluid',
		text: 'Finanzas',
		path: 'edit-pages/fluid',
		icon: 'StackedBarChart',
		subMenu: {
			accounts: {
				id: 'accounts',
				text: 'Cuentas',
				path: 'accounts',
				icon: 'CreditCard',
			},
			dues: {
				id: 'dues',
				text: 'Registro de cuotas',
				path: 'dues',
				icon: 'AppRegistration',
			},
			charges: {
				id: 'charges',
				text: 'Aplicar cuota',
				path: 'charges',
				icon: 'Payments',
			},
			ingress: {
				id: 'Ingress',
				text: 'Otros ingresos',
				path: 'ingress',
				icon: 'MonetizationOn',
			},
			egress: {
				id: 'Egress',
				text: 'Gastos',
				path: 'egress',
				icon: 'MoneyOffCsred',
			},
			budget: {
				id: 'budget',
				text: 'Presupuestos',
				path: 'budget',
				icon: 'Assessment',
			},
			balance: {
				id: 'Balance',
				text: 'Balance',
				path: 'balance',
				icon: 'AccountBalance',
			},
			adjustment: {
				id: 'adjustment',
				text: 'Ajustar saldos',
				path: 'adjustment',
				icon: 'CreditScore',
			},
			reporter: {
				id: 'reporter',
				text: 'Estado de resultados',
				path: 'reporter',
				icon: 'AreaChart',
			},
		},
	},
	listFluid: {
		id: 'listFluid',
		text: 'Control de acceso',
		path: 'access',
		icon: 'LocalPolice',
		subMenu: {
			addStickers: {
				id: 'stickers',
				text: 'ID cards',
				path: 'stickers',
				icon: 'Style',
			},
		},
	},
	editInModal: {
		id: 'editInModal',
		text: 'Configuraciones',
		path: 'settings',
		icon: 'PieChartFill',
		subMenu: {
			administrators: {
				id: 'administrators',
				text: 'Perfil',
				path: 'administrators',
				icon: 'Groups',
			},
			invite: {
				id: 'invitationToManege',
				text: 'Invitaciones',
				path: 'invitationToManege',
				icon: 'EnvelopeFill',
			},
		},
	}, */
};

export const noMenuPages = {
	pricingTable: {
		id: 'pricingTable',
		text: 'Pricing Table',
		path: 'pricing-table',
		icon: 'Local Offer',
	},
	auth: {
		id: 'auth',
		text: 'Páginas',
		icon: 'Extension',
	},
	invite: {
		id: 'invite',
		text: 'Invite',
		path: 'invite',
		icon: 'Login',
	},
	login: {
		id: 'login',
		text: 'Login',
		path: 'login',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'sign-up',
		icon: 'PersonAdd',
	},
	administrator: {
		id: 'admin',
		text: 'Administradores',
		path: 'administrator',
		icon: 'ContactPage',
	},
	loaderLaunch: {
		id: 'loaderLaunch',
		text: 'Loader Launch',
		path: '/',
		icon: 'Login',
	},
	recoverPasswordStepOne: {
		id: 'recoverPasswordStepOne',
		text: 'Recover Password Step One',
		path: 'recover-password-step-one',
		icon: 'Login',
	},
	recoverPasswordStepTwo: {
		id: 'recoverPasswordStepTwo',
		text: 'Recover Password Step Two',
		path: 'recover-password-step-two',
		icon: 'Login',
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: '404',
		icon: 'ReportGmailerrorred',
	},
	termsAndConditionsApp: {
		id: 'terms',
		text: 'terms',
		path: 'terms',
		icon: 'Terms',
	},
};

export const productsMenu = {
	companyA: { id: 'companyA', text: 'Company A', path: 'grid-pages/products', subMenu: null },
	companyB: { id: 'companyB', text: 'Company B', path: '/', subMenu: null },
	companyC: { id: 'companyC', text: 'Company C', path: '/', subMenu: null },
	companyD: { id: 'companyD', text: 'Company D', path: '/', subMenu: null },
};
