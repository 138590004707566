import React, { useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { HeaderRight } from '../../../layout/Header/Header';
import OffCanvas, {
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';

// eslint-disable-next-line react/prop-types
const CommonHeaderRightImage = ({ afterChildren }) => {
	const [offcanvasStatus, setOffcanvasStatus] = useState(false);
	const { i18n } = useTranslation();

	/**
	 * Language attribute
	 */
	useLayoutEffect(() => {
		document.documentElement.setAttribute('lang', i18n.language);
	});

	return (
		<HeaderRight>
			{afterChildren}

			<OffCanvas
				id='notificationCanvas'
				titleId='offcanvasExampleLabel'
				placement='end'
				isOpen={offcanvasStatus}
				setOpen={setOffcanvasStatus}>
				<OffCanvasHeader setOpen={setOffcanvasStatus}>
					<OffCanvasTitle id='offcanvasExampleLabel'>Notificaciones</OffCanvasTitle>
				</OffCanvasHeader>
			</OffCanvas>
		</HeaderRight>
	);
};
CommonHeaderRightImage.propTypes = {
	afterChildren: PropTypes.node,
};
CommonHeaderRightImage.defaultProps = {
	afterChildren: null,
};

export default CommonHeaderRightImage;
