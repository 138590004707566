export function getHeaderSimple() {
	return {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		token: `${localStorage.getItem('USER_TOKEN')}`,
		tenant: `${localStorage.getItem('USER_TENANT')}`,
		tenantname: `${localStorage.getItem('USER_CONDO_NAME')}`,
		dbname: `${localStorage.getItem('DB_NAME')}`,
	};
}

export function getHeaderMultipart() {
	return {
		'Content-Type': 'multipart/form-data',
		token: `${localStorage.getItem('USER_TOKEN')}`,
		tenant: `${localStorage.getItem('USER_TENANT')}`,
		tenantname: `${localStorage.getItem('USER_CONDO_NAME')}`,
		dbname: `${localStorage.getItem('DB_NAME')}`,
	};
}
