import React, { useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapperLogin from '../../../layout/PageWrapper/PageWrapperLogin';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import InputTwo from '../../../components/bootstrap/forms/InputTwo';
import Spinner from '../../../components/bootstrap/Spinner';
import ButtonTwo from '../../../components/bootstrap/ButtonTwo';
import Logo from '../../../assets/logos/domusLogoLogin.png';
import PersonIcon from '../../../assets/img/Icono_usuario.png';
import LockIcon from '../../../assets/img/ICONO_CANDADO.png';
import Toggle from '../../../assets/img/ICONO_VISUALIZACION.png';
import ToggleOff from '../../../assets/img/ICONO_SIN_VISUALIZACION.png';
import ModalError from '../../../common/ModalError';
import { demoPages, noMenuPages, multiCondosPages } from '../../../menu';

// eslint-disable-next-line react/prop-types
const LoginHeader = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div
					className='text-center h1 fw-bold mt-5'
					style={{ fontSize: '40px', color: '#000' }}>
					Crea tu cuenta
				</div>
				<div
					className='text-center h4 text-muted mb-5'
					style={{ fontSize: '22px', color: '#6E000000' }}>
					Selecciona el tipo de cuenta
				</div>
			</>
		);
	}
	return (
		<>
			<div
				className='text-center h1 fw-bold mt-5'
				style={{ fontSize: '40px', color: '#000' }}>
				¡Bienvenido!
			</div>
			<div
				className='text-center h4 text-muted mb-5'
				style={{ fontSize: '22px', color: '#6E000000' }}>
				¡Inicia sesión para continuar!
			</div>
		</>
	);
};

/**
 * Style
 */
const useStyles = createUseStyles({
	image: {
		maxHeight: '74px',
		objectFit: 'contain',
	},
	inputBorder: {
		borderRadius: '18px',
		backgroundColor: '#fff',
		display: 'flex',
		paddingLeft: '28px',
		paddingRight: '28px',
		alignItems: 'center',
		height: '57px',
		boxShadow: '2px 15px 6px #1B36C61A',
		borderStyle: 'solid',
		borderWidth: '2px',
		borderColor: '#6228BF1A',
	},
	inputBorderPassword: {
		marginTop: '59px',
		borderRadius: '18px',
		backgroundColor: '#fff',
		display: 'flex',
		paddingLeft: '28px',
		paddingRight: '28px',
		alignItems: 'center',
		height: '57px',
		boxShadow: '2px 15px 6px #1B36C61A',
		borderStyle: 'solid',
		borderWidth: '2px',
		borderColor: '#6228BF1A',
	},
	inputBorderSubscribe: {
		borderRadius: '18px',
		backgroundColor: '#fff',
		display: 'flex',
		marginBottom: '10px',
		paddingLeft: '14px',
		paddingRight: '24px',
		alignItems: 'center',
		height: '57px',
		boxShadow: '2px 15px 6px #1B36C61A',
		borderStyle: 'solid',
		borderWidth: '2px',
		borderColor: '#6228BF1A',
	},
	noButtons: {
		'&::-webkit-outer-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0,
		},
		'&::-webkit-inner-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0,
		},
	},
});

const Login = ({ isSignUp }) => {
	const classes = useStyles();
	const navigate = useNavigate();

	const [isNewUser] = useState(isSignUp);
	const [activeButton, setActiveButton] = useState(false);
	const [seePassword, setSeePassword] = useState(false);
	const [userText, setUserText] = useState('');
	const [passwordText, setPasswordText] = useState('');
	const [keyUpdate, setKeyUpdate] = useState(0);
	const [errorUserText, setErrorUserText] = useState(false);
	const [errorPasswordText, setErrorPasswordText] = useState(false);
	const [showModalError, setShowModalError] = useState(false);
	const [loadingLogin, setLoadingLogin] = useState(false);

	const expressions = {
		password: /^.{6,}$/, // mínimo 8 digitos.
		validatePassword: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/, // mínimo 8 digitos, una letra mayúscula, una letra minúsula y un dígito.
		email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
	};

	const validateLoginButton = () => {
		setShowModalError(false);
		if (expressions.email.test(userText) && userText !== '') {
			if (expressions.password.test(passwordText) && passwordText !== '') {
				setActiveButton(true);
				setErrorUserText(false);
				setErrorPasswordText(false);
			} else {
				setActiveButton(false);
			}
		} else {
			setActiveButton(false);
		}

		setKeyUpdate(keyUpdate + 1);
	};

	const onLoginClic = async () => {
		if (activeButton) {
			setLoadingLogin(true);
			const loginBody = {
				email: userText,
				password: passwordText,
			};

			const response = await fetch(`${process.env.REACT_APP_API_URL}auth/singin`, {
				method: 'POST',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
				body: JSON.stringify(loginBody),
			});
			const json = await response.json();

			if (json.auth === false) {
				setLoadingLogin(false);
				setShowModalError(true);
				setErrorUserText(true);
				setErrorPasswordText(true);
				setActiveButton(false);
			} else {
				localStorage.setItem('USER_TOKEN', json.token);
				localStorage.setItem('USER_ID', json.userId);
				localStorage.setItem('USER_FULL_NAME', json.userFullName);
				localStorage.setItem('USER_TENANT', json.tenant);
				localStorage.setItem('USER_IMAGE', json.userImage);
				localStorage.setItem('USER_CONDO_NAME', json.condoName);
				localStorage.setItem('USER_CONDO_ALIAS', json.condoAlias);
				localStorage.setItem('USER_MULTITENANT', json.multiCondos);
				localStorage.setItem('DB_NAME', json.tenant);

				setActiveButton(false);
				setSeePassword(false);
				setUserText('');
				setPasswordText('');
				setErrorUserText(false);
				setErrorPasswordText(false);
				setLoadingLogin(false);

				if (json.multiCondos === false) {
					if (json.adminStatus === 'preregistration') {
						navigate(`../${demoPages.dashboard.path}`, { replace: true });
					} else {
						navigate(`../${noMenuPages.administrator.path}`, { replace: true });
					}
				} else {
					navigate(`../${multiCondosPages.workSpace.path}`);
				}
			}
		}
	};

	return (
		<PageWrapperLogin className={classNames('bg-storybook')}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div
						className={
							isNewUser
								? 'col-xl-8 col-lg-6 col-md-8 shadow-3d-container'
								: 'col-xl-4 col-lg-6 col-md-8 shadow-3d-container'
						}>
						<ModalError show={showModalError}>
							{!isNewUser ? (
								<div style={{ marginTop: '6px', paddingBottom: '12px' }}>
									<p
										style={{
											fontSize: '16px',
											fontWeight: '500',
											textAlign: 'center',
										}}>
										Los datos que ingresó son incorrectos, favor de verificarlos
									</p>
								</div>
							) : (
								<div
									style={{
										marginTop: '6px',
										paddingBottom: '30px',
										marginLeft: '24px',
										marginRight: '24px',
									}}>
									<p
										style={{
											fontSize: '18px',
											fontWeight: '700',
											textAlign: 'center',
										}}>
										El nombre de la privada seleccionada ya ha sido registrado
									</p>
									<view
										style={{
											flexDirection: 'row',
											justifyContent: 'space-between',
											alignItems: 'baseline',
											textAlign: 'center',
										}}>
										<text style={{ fontSize: '16px', fontWeight: '400' }}>
											Si eres el administrador de esta privada, favor de
											comunicarte al
										</text>
										<span> </span>
										<text
											style={{
												fontSize: '16px',
												fontWeight: '400',
												color: '#1B36C6',
											}}>
											6688 00 00 00
										</text>
										<text style={{ fontSize: '16px', fontWeight: '400' }}>
											, o envía un correo a
										</text>
										<span> </span>
										<text
											style={{
												fontSize: '16px',
												fontWeight: '400',
												color: '#1B36C6',
											}}>
											soporte@domusaccess.com
										</text>
									</view>
								</div>
							)}
						</ModalError>
						<Card
							className='shadow-3d-light'
							data-tour='login-page'
							style={{ borderRadius: '50px', marginTop: '30px' }}>
							<CardBody
								className='pt-0'
								style={{ marginLeft: '4px', marginRight: '4px' }}>
								<div className='text-center my-5'>
									<img src={Logo} className={classes.image} width='100%' alt='' />
								</div>
								<LoginHeader isNewUser={isNewUser} />

								<form className='row g-4'>
									<div className='col-12'>
										<div className={classes.inputBorder}>
											<div
												style={{
													height: '20px',
													width: '16px',
													marginRight: '5px',
												}}>
												<img
													src={PersonIcon}
													className={classes.image}
													width='100%'
													alt=''
												/>
											</div>

											<InputTwo
												autoComplete='username'
												value={userText}
												placeholder='Usuario'
												isError={errorUserText}
												onChange={(e) => {
													setUserText(e.target.value);
													setErrorUserText(false);
												}}
												onKeyUp={(e) => {
													setUserText(e.target.value);
													setErrorUserText(false);
													setKeyUpdate(keyUpdate + 1);
													validateLoginButton();
												}}
											/>
										</div>

										<div className={classes.inputBorderPassword}>
											<div
												style={{
													height: '20px',
													width: '16px',
													marginRight: '5px',
												}}>
												<img
													src={LockIcon}
													className={classes.image}
													width='100%'
													alt=''
												/>
											</div>
											<InputTwo
												type={seePassword ? 'text' : 'password'}
												autoComplete='password'
												value={passwordText}
												placeholder='Contraseña'
												isError={errorPasswordText}
												onChange={(e) => {
													setPasswordText(e.target.value);
													setErrorPasswordText(false);
												}}
												onKeyUp={(e) => {
													setPasswordText(e.target.value);
													setErrorPasswordText(false);
													setKeyUpdate(keyUpdate + 1);
													validateLoginButton();
												}}
												onKeyPress={(event) => {
													if (event.key === 'Enter') {
														onLoginClic();
													}
												}}
											/>
											<div
												style={{
													height: '16px',
													width: '20px',
													marginLeft: '5px',
												}}
												role='presentation'
												onClick={() => {
													setSeePassword(!seePassword);
												}}
												onKeyPress={() => {
													setSeePassword(!seePassword);
												}}>
												<img
													src={seePassword ? Toggle : ToggleOff}
													width='100%'
													alt=''
												/>
											</div>
										</div>
									</div>
									<div
										className='col-6'
										style={{
											margin: 'auto',
											marginTop: '101px',
											marginBottom: '50px',
										}}>
										{loadingLogin ? (
											<div style={{ textAlign: 'center' }}>
												<Spinner color='info' />
											</div>
										) : (
											<ButtonTwo
												color={
													!activeButton
														? 'purple-main'
														: 'blue-gradient-one'
												}
												className='w-100 py-3'
												isLink={!activeButton}
												onClick={onLoginClic}
												style={
													!activeButton
														? { color: '#FFFFFF' }
														: {
																color: '#FFFFFF',
																boxShadow: '2px 2px 10px #1B36C699',
														  }
												}>
												Iniciar
											</ButtonTwo>
										)}
									</div>
								</form>
							</CardBody>
						</Card>
						<div
							className='text-center'
							style={{ marginTop: '40px', marginBottom: '50px' }}>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': !isNewUser,
									'link-dark': isNewUser,
								})}
								style={{ fontSize: '14px', color: '#fff' }}>
								Políticas de privacidad
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': !isNewUser,
									'link-dark': isNewUser,
								})}
								style={{ fontSize: '14px', color: '#fff' }}>
								Condiciones de uso
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapperLogin>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
