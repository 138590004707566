import defaultImage from './img/defaultimage.png';
import defaultAvatar from './img/userd.png';
import defaultMaps from './img/maps.jpeg';
import defaultCar from './img/carol.png';
import defaultPet from './img/pets.png';
import defaultDoors from './img/puertasnegras.jpeg';
import defaultPet2 from './img/dogecoin.jpeg';
import defaultAllAvatar from './img/all_residents.png';
import defaultProduct from './img/ProductImage.png';
import defaultReport from './img/ReportImage.png';

export default {
	imgDefault: defaultImage,
	imgAvatar: defaultAvatar,
	imgMaps: defaultMaps,
	imgCar: defaultCar,
	imgPet: defaultPet,
	imgPet2: defaultPet2,
	imgDoors: defaultDoors,
	imgAll: defaultAllAvatar,
	imgProduct: defaultProduct,
	imgReport: defaultReport,
};
