import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import ModalTwo, { ModalBody } from '../components/bootstrap/ModalTwo';
import imageError from '../assets/img/ICONO_ERROR.png';

const ModalError = forwardRef(({ children, show }) => {
	const [isOpenModal, setIsOpenModal] = useState(false);
	const centerModal = true;

	useEffect(() => {
		setIsOpenModal(show);
	}, [show]);

	return (
		<ModalTwo
			isOpen={isOpenModal}
			setIsOpen={setIsOpenModal}
			titleId='tour-title'
			isCentered={centerModal}
			size='sm'>
			<ModalBody setIsOpen={setIsOpenModal}>
				<div>
					<div
						style={{
							width: '85px',
							height: '85px',
							margin: 'auto',
						}}>
						<img src={imageError} width='100%' alt='' />
					</div>
					{children}
				</div>
			</ModalBody>
		</ModalTwo>
	);
});

ModalError.propTypes = {
	children: PropTypes.node,
	show: PropTypes.bool,
};
ModalError.defaultProps = {
	children: null,
	show: false,
};

export default ModalError;
